.SlateEditor {

  font-size: 0.8em;

  ul {
    padding-left: 20px;
    list-style-type: disc;
  }

  ol {
    padding-left: 20px;
    list-style-type: decimal;
  }

  blockquote {
    border-left: 2px solid #ddd;
    margin-left: 0;
    margin-right: 0;
    padding-left: 10px;
    color: #aaa;
    font-style: italic;
  }

  h1 {
    font-size: 2em;
    margin: 0 1em 0 0;
    @apply text-primary font-bold;
  }

  h2 {
    font-size: 1.6em;
    margin: 0 0.8em 0 0;
    @apply text-primary font-semibold;
  }

  [data-slate-placeholder='true'] {
    color: #333;
    font-style: italic;
    margin: 5px 0 0 0;
    font-size: 1em !important;
  }



}

.SlateEditorInverted {

  h1 {
    @apply text-white;
  }

  h2 {
    @apply text-white;
  }

}
